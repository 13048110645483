import { NgModule } from "@angular/core";
import { AtMessageModule, IconModule, MediaModule, OutletModule, SpinnerModule } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { I18nModule, UrlModule } from "@spartacus/core";

import { ProductGridItemComponent } from "./product-grid-item.component";
import { CustomProductAddToCartModule } from "../../../cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module";
import { SharedModule } from "../../../../../shared/shared.module";
import { InfoPriceModule } from "../../components/info-price/info-price.module";
import { InfoMessageModule } from "src/app/shared/components/info-message/info-message.module";
import { AddToFavoritesComponent } from "../../../favorites/add-to-favorites/add-to-favorites.component";

@NgModule({
  declarations: [ProductGridItemComponent],
    imports: [
        CommonModule,
        InfoPriceModule,
        MediaModule,
        CustomProductAddToCartModule,
        RouterModule,
        InfoMessageModule,
        OutletModule,
        UrlModule,
        AtMessageModule,
        I18nModule,
        SharedModule,
        SpinnerModule,
        IconModule,
        AddToFavoritesComponent,
    ],
  exports: [ProductGridItemComponent]
})
export class ProductGridItemModule { }
