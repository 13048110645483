/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, SpinnerModule } from '@spartacus/storefront';
import { InfoPriceComponent } from './info-price.component';

@NgModule({
  imports: [CommonModule, I18nModule, SpinnerModule, IconModule],
  declarations: [InfoPriceComponent],
  exports: [InfoPriceComponent],
})
export class InfoPriceModule {}
