import { DIALOG_TYPE, LayoutConfig } from "@spartacus/storefront";
import { CustomAddedToCartDialogComponent } from "../features/cart/components/custom-added-to-cart-dialog/custom-added-to-cart-dialog.component";
import { GlobalMessageConfig, PRODUCT_NORMALIZER, RoutingConfig } from "@spartacus/core";
import { ProductNormalizer } from "../../services/normaliser.service";
import { CheckoutConfig, CheckoutStepType, DeliveryModePreferences } from "@spartacus/checkout/base/root";
import { CheckoutStepTypeExtended } from "../../enums/checkout-step-type";
import { DelegoComponent } from "../features/checkout/components/delego/delego.component";
import { VerifyAddressComponent } from "../features/checkout/components/custom-checkout-delivery-address/verify-address/verify-address.component";
import { ProductImageZoomDialogComponent } from "@spartacus/product/image-zoom/components";
import { GlobalMessageType } from '@spartacus/core';
import { AddToFavoritesDialogComponent } from "../features/favorites/add-to-favorites-dialog/add-to-favorites-dialog.component";
import { CreateOrEditFavoritesListDialogComponent } from "../features/favorites/create-or-edit-favorites-list-dialog/create-or-edit-favorites-list-dialog.component";
import { FavoritesConfirmActionDialogComponent } from "../features/favorites/favorites-confirm-action-dialog/favorites-confirm-action-dialog.component";
import { FAVORITES_DIALOGS } from "src/app/interfaces/favorites.model";
import { FavoriteProductsAddedToCartDialogComponent } from "../features/favorites/favorite-products-added-to-cart-dialog/favorite-products-added-to-cart-dialog.component";
import { OrderEntriesSource } from "@spartacus/cart/base/root";
import { CartNameSource, ImportExportConfig } from "@spartacus/cart/import-export/core";
import { WarningDialogComponent } from "src/app/shared/components/warning-dialog/warning-dialog.component";
import { EditSavedAddressDialogComponent } from "../features/saved-addresses/edit-saved-address-dialog/edit-saved-address-dialog.component";
import { DeleteSavedAddressDialogComponent } from "../features/saved-addresses/delete-saved-address-dialog/delete-saved-address-dialog.component";

export const defaultAddedToCartLayoutConfig: LayoutConfig = {
    launch: {
        ADDED_TO_CART: {
            inlineRoot: true,
            component: CustomAddedToCartDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultVerifyAddressLayoutConfig: LayoutConfig = {
    launch: {
        VERIFY_ADDRESS: {
            inlineRoot: true,
            component: VerifyAddressComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const delegoDialogLayoutConfig: LayoutConfig = {
    launch: {
        PAY_BY_DELEGO: {
            inline: true,
            component: DelegoComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const productNormaliserConfig = {
    provide: PRODUCT_NORMALIZER,
    useClass: ProductNormalizer,
    multi: true,
}

export const productRoutingConfig: RoutingConfig = {
    routing: {
        routes: {
            product: { paths: ['product/:productCode/:urlName'] },
            bundles: { paths: ['bundles/:productCode/:name'] },
            search: { paths: ['search/:query' ] },
        }
    }
}

export const defaultB2BCheckoutConfig: CheckoutConfig = {
    checkout: {
        steps: [
            {
                id: 'deliveryAddress',
                name: 'checkoutProgress.deliveryAddress',
                routeName: 'checkoutDeliveryAddress',
                type: [CheckoutStepType.DELIVERY_ADDRESS],
            },
            {
                id: 'subscriptionInfo',
                name: 'checkoutProgress.subscriptionInfo',
                routeName: 'checkoutSubscriptionInfo',
                type: [CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION as unknown as CheckoutStepType],
            },
            {
                id: 'specialHandling',
                name: 'checkoutProgress.specialHandlingTab',
                routeName: 'checkoutSpecialHandling',
                type: [CheckoutStepTypeExtended.SPECIAL_HANDLING as unknown as CheckoutStepType]
            },
            {
                id: 'paymentType',
                name: 'checkoutProgress.paymentDetails',
                routeName: 'checkoutPaymentType',
                type: [CheckoutStepType.PAYMENT_TYPE],
            },
            {
                id: 'reviewOrder',
                name: 'checkoutProgress.reviewOrder',
                routeName: 'checkoutReviewOrder',
                type: [CheckoutStepType.REVIEW_ORDER],
            },
        ],
        express: false,
        defaultDeliveryMode: [DeliveryModePreferences.FREE],
        guest: false,
    },
};

export const defaultCheckoutRoutingConfig: RoutingConfig = {
    routing: {
        routes: {
            checkoutLogin: { paths: ['checkout-login'], authFlow: true },
            checkout: { paths: ['checkout'] },
            checkoutSpecialHandling: { paths: ['checkout/special-handling'] },
            checkoutDeliveryAddress: { paths: ['checkout/delivery-address'] },
            checkoutSubscriptionInfo: { paths: ['checkout/subscription-info'] },
            checkoutPaymentDetails: { paths: ['checkout/payment-details'] },
            checkoutReviewOrder: { paths: ['checkout/review-order'] },
        },
    },
};

export const defaultStorefrontRoutesConfig: RoutingConfig = {
    routing: {
        routes: {
            termsAndConditions: { paths: ['terms-and-conditions'] },
            termsAndConditionsCheckout: { paths: ['terms-and-conditions-checkout'] },
            termsAndConditionsSubscription: { paths: ['terms-and-conditions-subscription'] },
        },
    },
};

export const subscriptionCodes = ['G0064150', 'G0066770', 'G0098640', 'G0098660'];

export const defaultProductImageZoomLayoutConfig: LayoutConfig = {
    launch: {
        PRODUCT_IMAGE_ZOOM: {
            inlineRoot: true,
            component: ProductImageZoomDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultAddToFavoritesLayoutConfig: LayoutConfig = {
    launch: {
        [FAVORITES_DIALOGS.ADD_TO_FAVORITES]: {
            inlineRoot: true,
            component: AddToFavoritesDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultCreateOrEditFavoritesListLayoutConfig: LayoutConfig = {
    launch: {
        [FAVORITES_DIALOGS.CREATE_OR_EDIT_FAVORITES_LIST]: {
            inlineRoot: true,
            component: CreateOrEditFavoritesListDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultFavoritesConfirmActionLayoutConfig: LayoutConfig = {
    launch: {
        [FAVORITES_DIALOGS.FAVORITES_CONFIRM_ACTION]: {
            inlineRoot: true,
            component: FavoritesConfirmActionDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultFavoriteProductsAddedToCartLayoutConfig: LayoutConfig = {
    launch: {
        [FAVORITES_DIALOGS.FAVORITE_PRODUCTS_ADDED_TO_CART]: {
            inlineRoot: true,
            component: FavoriteProductsAddedToCartDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultWarningDialogLayoutConfig: LayoutConfig = {
    launch: {
        WARNING_DIALOG: {
            inlineRoot: true,
            component: WarningDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultEditSavedAddressDialogLayoutConfig: LayoutConfig = {
    launch: {
        EDIT_SAVED_ADDRESS: {
            inlineRoot: true,
            component: EditSavedAddressDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultDeleteSavedAddressDialogLayoutConfig: LayoutConfig = {
    launch: {
        DELETE_SAVED_ADDRESS: {
            inlineRoot: true,
            component: DeleteSavedAddressDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export const defaultGlobalMessageConfig: GlobalMessageConfig = {
    globalMessages: {
      [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
        timeout: 30000,
      },
      [GlobalMessageType.MSG_TYPE_INFO]: {
        timeout: 30000,
      },
      [GlobalMessageType.MSG_TYPE_ERROR]: {
        timeout: 30000,
      },
      [GlobalMessageType.MSG_TYPE_WARNING]: {
        timeout: 30000,
      },
      [GlobalMessageType.MSG_TYPE_ASSISTIVE]: {
        timeout: 30000,
      },
    },
  };

export const defaultImportExportConfig: ImportExportConfig = {
    cartImportExport: {
        file: {
            separator: ',',
        },
        import: {
            fileValidity: {
                maxSize: 1,
                maxEntries: {
                    [OrderEntriesSource.NEW_SAVED_CART]: 100,
                    [OrderEntriesSource.SAVED_CART]: 100,
                    [OrderEntriesSource.ACTIVE_CART]: 10,
                    [OrderEntriesSource.QUICK_ORDER]: 10,
                },
                allowedTypes: [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'text/csv',
                    '.csv',
                ],
            },
            cartNameGeneration: {
                source: CartNameSource.FILE_NAME,
            },
        },
        export: {
            additionalColumns: [
                {
                    name: {
                        key: 'name',
                    },
                    value: 'product.name',
                },
                {
                    name: {
                        key: 'unitPrice',
                    },
                    value: 'basePrice.formattedValue',
                },
                {
                    name: {
                        key: 'price',
                    },
                    value: 'subTotalEntryPrice.formattedValue',
                },
            ],
            messageEnabled: true,
            downloadDelay: 1000,
            maxEntries: 1000,
            fileOptions: {
                fileName: 'cart',
                extension: 'csv',
                type: 'text/csv',
            },
        },
    },
};
