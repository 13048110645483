import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { ProductService } from 'src/app/services/product.service';
import { finalize, take, tap } from 'rxjs/operators';
import { CurrentProductService, ICON_TYPE } from '@spartacus/storefront';
import { User } from '@spartacus/core';
import { MSRPPrice, ProductWarranty } from 'src/app/interfaces/product';
import { normalizeSlug } from '../../../helpers/slug.helper';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';

@Component({
  selector: 'generac-product-warranty-item',
  templateUrl: './generac-product-warranty-item.component.html',
  styleUrls: ['./generac-product-warranty-item.component.scss'],
})
export class GeneracProductWarrantyItemComponent implements OnInit {
  @Input() warranty: ProductWarranty;
  @Input() userUid: string;
  _phoneNumberOrEmail: string;
  phoneOrEmail: any;
  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }
  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }
  @Input() tooltipMessage: string;
  subscription = new Subscription();
  price: MSRPPrice;
  isOpen: boolean;
  isReady: boolean;
  user: User;
  netPrice: string;
  currency: string;
  iconTypes = ICON_TYPE;
  warrantyHref: string;
  callForPricingMessage = '';
  contactForPricingHref: string;
  gaListName: GaListNames = GaListNames.WARRANTIES;

  constructor(
    protected productService: ProductService,
    protected currentProductService: CurrentProductService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.warrantyHref = `/product/${this.warranty.code}/${normalizeSlug(this.warranty.name)}?gaListName=${this.gaListName}`;
  }

  public getPrice(warranty: ProductWarranty): void {
    this.isOpen = true;
    this.isReady = false;
    this.productService.getCPIPrice(this.userUid, warranty)
      .pipe(
        take(1),
        tap((price: MSRPPrice) => {
          if (price) {
            this.netPrice = price.value;
            this.currency = price.currency;
            this.cdr.markForCheck();
          }
        }),
        finalize(() => {
          this.isReady = true;
          this.cdr.markForCheck();
        })
      )
      .subscribe()
  }
}
