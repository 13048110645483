<div class="row plp-desktop-item" *ngIf="!isMobileView; else plpMbileTmpl">
  <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
    class="cx-product-image-container">
    <cx-media class="cx-product-image" [container]="product.images?.PRIMARY" format="product"
      [alt]="product.name || product.summary"></cx-media>
  </a>
  <div class="product-name-container">
    <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      >
      <span class="name">{{ product.name }}</span>
    </a>
    <a *ngIf="product.bundleAvailable"
      [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
      class="buy-in-bundle call-for-pricing link cx-action-btn-link">
      Buy In Bundle >
    </a>
  </div>
  <div class="price" *ngIf="product.purchasable">
    <div class="product-ltl" *ngIf="product.ltl">
      <ng-container *ngIf="(tooltipLtlMessage$ | async) as tooltipLtlMessage">
        <div class="position-relative" (mouseenter)="ltlMessage=tooltipLtlMessage"
          (mouseleave)="ltlMessage=''">
            <img src="../../../../../assets/icons/truck_icon_24.svg">
            <cx-info-message [message]="ltlMessage" [position]="'position-right'"></cx-info-message>
        </div>
      </ng-container>
    </div>
    <cx-icon (cxClickOutside)="isOpen = false" class="regular-icon grey" [type]="iconTypes.INFO"
      (click)="getPrice(product);isOpen=true;"></cx-icon>
    {{ getDisplayPrice(product) }}
    <cx-info-price *ngIf="product.purchasable" [isOpen]="isOpen" [isReady]="isReady" [netPrice]="netPrice"
      [msrpPrice]="product.price?.formattedValue" [isServicePart]="product?.servicePart"></cx-info-price>
  </div>
  <div *ngIf="!product.purchasable" class="cx-product-price-container non-price">
    <add-to-favorites [product]="product" [class.border-grey]="product.erpStatus !== 'DISCONTINUED'"></add-to-favorites>
    <div class="position-relative" (mouseenter)="callForPricingMessage=tooltipMessage"
      (mouseleave)="callForPricingMessage=''">
      <a [href]="contactForPricingHref" class="call-for-pricing link cx-action-btn-link">
        Contact For Pricing
        <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
      </a>
    </div>
  </div>
  <cx-add-to-cart *ngIf="product.purchasable" [product]="product" [purchasable]="true" [productCode]="product.code"
    [showQuantity]="true" [isIcon]="true" [gaListName]="gaListName"></cx-add-to-cart>
  <div class="sp-note-container" *ngIf="isServicePartsEnabled && product?.materialNotes && product?.servicePart">
    <span>
      <strong>Note: </strong>
      {{product?.materialNotes}}
    </span>
  </div>
</div>

<ng-template #plpMbileTmpl>
  <div class="row plp-mobile-item">
    <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      class="cx-product-image-container">
      <cx-media class="cx-product-image" [container]="product.images?.PRIMARY" format="product"
        [alt]="product.name || product.summary"></cx-media>
    </a>
    <div class="plp-mobile-product-data">
      <div class="product-name-container">
        <a [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" (click)="selectItem(product)"
      [queryParams]="{gaListName: gaListName}"
      >
          <span class="name">{{ product.name }}</span>
        </a>
        <div class="price" *ngIf="product.purchasable">
          {{ product.price?.formattedValue }}
          <cx-icon (cxClickOutside)="isOpen = false" class="regular-icon grey" [type]="iconTypes.INFO"
            (click)="getPrice(product)"></cx-icon>
          <cx-info-price *ngIf="product.purchasable" [isOpen]="isOpen" [isReady]="isReady" [netPrice]="netPrice"
            [msrpPrice]="product.price?.formattedValue" [isServicePart]="product?.servicePart"></cx-info-price>
        </div>
        <a *ngIf="product.bundleAvailable"
          [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
          class="buy-in-bundle call-for-pricing link cx-action-btn-link">
          Buy In Bundle >
        </a>
        <div class="product-ltl" *ngIf="product.ltl">
          <ng-container *ngIf="(tooltipLtlMessage$ | async) as tooltipLtlMessage">
            <div class="position-relative" (mouseenter)="ltlMessage=tooltipLtlMessage"
              (mouseleave)="ltlMessage=''">
              <img src="../../../../../assets/icons/truck_icon_24.svg">
              <cx-info-message [message]="ltlMessage" [position]="'position-right'"></cx-info-message>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!product.purchasable" class="cx-product-price-container non-price">
        <add-to-favorites [product]="product"
          [class.border-grey]="product.erpStatus !== 'DISCONTINUED'"></add-to-favorites>
        <div class="position-relative" (mouseenter)="callForPricingMessage=tooltipMessage"
          (mouseleave)="callForPricingMessage=''">
          <a [href]="contactForPricingHref" class="call-for-pricing link cx-action-btn-link">
            Contact For Pricing
            <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
          </a>
        </div>
      </div>
      <cx-add-to-cart *ngIf="product.purchasable" [product]="product" [purchasable]="true" [productCode]="product.code"
        [showQuantity]="true" [isMobileBtn]="true" [gaListName]="gaListName"></cx-add-to-cart>
    </div>
  </div>
</ng-template>