import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutReplenishmentFormService } from '@spartacus/checkout/scheduled-replenishment/components';
import { EventService, OccEndpointsService } from '@spartacus/core';
import { ORDER_TYPE } from '@spartacus/order/root';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GeneracScheduleReplenishmentForm } from 'src/app/interfaces/cart';
@Injectable({
  providedIn: 'root',
})
export class GeneracCheckoutReplenishmentFormService extends CheckoutReplenishmentFormService {
 
  override defaultFormData: GeneracScheduleReplenishmentForm = {};

  constructor(
    protected override eventService: EventService,
    private occEndpoints: OccEndpointsService,
    private httpClient: HttpClient,
  ) {
    super(eventService);
  }

  scheduleGeneracReplenishmentFormData$: Observable<GeneracScheduleReplenishmentForm> =
    new BehaviorSubject<GeneracScheduleReplenishmentForm>(this.defaultFormData);

  override getScheduleReplenishmentFormData(): Observable<GeneracScheduleReplenishmentForm> {
    return this.scheduleGeneracReplenishmentFormData$;
  }

  override  setScheduleReplenishmentFormData(formData: GeneracScheduleReplenishmentForm): void {
    (
      this
        .scheduleGeneracReplenishmentFormData$ as BehaviorSubject<GeneracScheduleReplenishmentForm>
    ).next(formData);
  }

  setReplenishmentOrder(cartId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/cart/${cartId}/replenishmentSchedule`;
    return this.getScheduleReplenishmentFormData().pipe(
      switchMap(data => this.getOrderType().pipe(map(orderType => [orderType, data]))),
      switchMap(([orderType, data]: any) => {
        if (
          !data.mode ||
          (orderType === ORDER_TYPE.PLACE_ORDER && data.mode === 'REPLENISHMENT')
        ) {
          return EMPTY;
        }

        if (data.mode === 'ONE_TIME') {
          const desiredShipment = {
            mode: data.mode,
            desiredShipmentDate: data.desiredShipmentDate
          };
          return this.httpClient.post<any>(url, desiredShipment);
        }
        return this.httpClient.post<any>(url, data);
      })
    )
  }
}
