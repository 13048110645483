import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductListItemContext } from '../model/product-list-item-context.model';
import { ICON_TYPE, ProductListItemContextSource, ProductListOutlets } from '@spartacus/storefront';
import { MSRPPrice, ProductExtended, ProductScaleQuantityToPrice } from 'src/app/interfaces/product';
import { ProductService } from 'src/app/services/product.service';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { TranslationService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cx-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductListItemComponent implements OnChanges {
  readonly ProductListOutlets = ProductListOutlets;
  netPrice: string;
  currency: string;
  isOpen: boolean;
  isReady: boolean;
  callForPricingMessage = '';
  contactForPricingHref: string;
  isMobileView: boolean;
  isServicePartsEnabled: boolean = false;
  @Input() product: any;
  @Input() gaProductCategories: { name: string }[];
  @Input() gaListName: string;
  ltlMessage: string = '';
  tooltipLtlMessage$: Observable<string>;

  _phoneNumberOrEmail: string;
  iconTypes = ICON_TYPE;
  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }
  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }

  @Input() tooltipMessage: string;

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
  }

  private user: User;

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected winRef: WindowRef,
    protected translation: TranslationService,
    private productService: ProductService,
    private userAccount: UserAccountFacade,
    private cdr: ChangeDetectorRef,
  ) {
    this.isMobileView = this.winRef?.nativeWindow.innerWidth < 768;
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    this.tooltipLtlMessage$ = this.translation.translate('productList.tooltipLtlMessage');
    this.userAccount.get()
      .pipe(
        filter(Boolean),
        tap(user => this.user = user)
      )
      .subscribe()
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.product) {
      this.productListItemContextSource.product$.next(this.product);
    }
  }

  getPrice(product: ProductExtended): void {
    this.isOpen = true;
    this.isReady = false;
    this.productService.getCPIPrice(this.user?.uid, product)
      .pipe(
        take(1),
        tap((price: MSRPPrice) => {
          if (price) {
            this.netPrice = price.value;
            this.currency = price.currency;
            this.cdr.markForCheck();
          }
        }),
        finalize(() => {
          this.isReady = true;
          this.cdr.markForCheck();
        })
      )
      .subscribe()
  }

  selectItem(item: ProductExtended): void {
    const product = {
      ...item,
      categories: this.gaProductCategories,
    };
    this.googleAnalyticsService.sendGaEvent('select_item', {
      items: [this.googleAnalyticsService.mapProductToGaItem(product, 0, undefined, this.gaListName, this.gaListName)],
      item_list_name: this.gaListName || '',
      item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : '',
      currency: product.price?.currencyIso,
    });
  }

  getDisplayPrice(product: any) {
    const quantity = this.productService.getQuantity(product.code);
    let matchingPrice = this.product.price?.formattedValue;

    if (this.product.scaleQuantityToPrice?.length > 1) {
      const sortedScales = [...this.product.scaleQuantityToPrice].sort(
        (a: ProductScaleQuantityToPrice, b: ProductScaleQuantityToPrice) => parseInt(a.scale, 10) - parseInt(b.scale, 10)
      );

      for (let i = 0; i < sortedScales.length; i++) {
        const currentScale = parseInt(sortedScales[i].scale, 10);

        if (quantity >= currentScale) {
          matchingPrice = sortedScales[i].price?.formattedValue;
        } else {
          break;
        }
      }
    }

    return matchingPrice;
  }
}
