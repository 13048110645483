import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DeliveryMode, PaymentType, PaymentDetails } from '@spartacus/cart/base/root';
import { Address, CostCenter, TranslationService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ContactInformation, HandlingOption } from "../../../../../../interfaces/cart";
import { concatStrings } from "../../../../../../shared/helpers/concat-strings";
import moment from 'moment';
import { CartType } from 'src/app/enums/cart-type.enum';


@Component({
    selector: 'generac-order-overview',
    templateUrl: './order-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracOrderOverviewComponent {
    order: any;
    isSubscription = false;

    @Input('order')
    set setOrder(order: any) {
        this.order = order;
        this.isSubscription = order.cartTypes?.length === 1 && order.cartTypes[0] === CartType.SUBSCRIPTION.toString();
    }

    constructor(protected translation: TranslationService) {}

    getReplenishmentCodeCardContent(orderCode: string): Observable<Card> {
        return this.translation.translate('orderDetails.replenishmentId').pipe(
            filter(() => Boolean(orderCode)),
            map((textTitle) => ({
                title: textTitle,
                text: [orderCode],
            }))
        );
    }

    getReplenishmentActiveCardContent(active: boolean): Observable<Card> {
        return combineLatest([
            this.translation.translate('orderDetails.status'),
            this.translation.translate('orderDetails.active'),
            this.translation.translate('orderDetails.cancelled'),
        ]).pipe(
            map(([textTitle, textActive, textCancelled]) => ({
                title: textTitle,
                text: [active ? textActive : textCancelled],
            }))
        );
    }

    getReplenishmentStartOnCardContent(isoDate: string | null): Observable<Card> {
        return this.translation.translate('orderDetails.startOn').pipe(
            filter(() => Boolean(isoDate)),
            map((textTitle) => {
                return {
                    title: textTitle,
                    text: [isoDate],
                } as Card;
            })
        );
    }

    getReplenishmentFrequencyCardContent(frequency: string): Observable<Card> {
        return this.translation.translate('orderDetails.frequency').pipe(
            filter(() => Boolean(frequency)),
            map((textTitle) => ({
                title: textTitle,
                text: [frequency],
            }))
        );
    }

    getReplenishmentNextDateCardContent(
        isoDate: string | null
    ): Observable<Card> {
        return this.translation.translate('orderDetails.nextOrderDate').pipe(
            filter(() => Boolean(isoDate)),
            map((textTitle) => {
                return {
                    title: textTitle,
                    text: [isoDate],
                } as Card;
            })
        );
    }

    getOrderCodeCardContent(orderCode: string): Observable<Card> {
        return this.translation.translate('orderDetails.orderNumber').pipe(
            filter(() => Boolean(orderCode)),
            map((textTitle) => ({
                title: textTitle,
                text: [orderCode],
            }))
        );
    }

    getOrderCurrentDateCardContent(isoDate: string | null): Observable<Card> {
        return this.translation.translate('orderDetails.placedOn').pipe(
            filter(() => Boolean(isoDate)),
            map((textTitle) => {
                return {
                    title: textTitle,
                    text: [isoDate],
                } as Card;
            })
        );
    }

    getOrderStatusCardContent(status: string): Observable<Card> {
        return combineLatest([
            this.translation.translate('orderDetails.status'),
            this.translation.translate('orderDetails.statusDisplay_' + status),
        ]).pipe(
            map(([textTitle, textStatus]) => ({
                title: textTitle,
                text: [textStatus],
            }))
        );
    }

    getPurchaseOrderNumber(poNumber: string): Observable<Card> {
        return combineLatest([
            this.translation.translate('orderDetails.purchaseOrderNumber'),
            this.translation.translate('orderDetails.emptyPurchaseOrderId'),
        ]).pipe(
            map(([textTitle, noneTextTitle]) => ({
                title: textTitle,
                text: [poNumber ? poNumber : noneTextTitle],
            }))
        );
    }

    getMethodOfPaymentCardContent(paymentType: PaymentType): Observable<Card> {
        return combineLatest([
            this.translation.translate('orderDetails.paymentDetails'),
            this.translation.translate('paymentTypes.paymentType_ACCOUNT'),
            this.translation.translate('paymentTypes.paymentType_CARD'),
            this.translation.translate('paymentTypes.paymentType_WELLS_FARGO'),
            this.translation.translate('paymentTypes.paymentType_BANK_TRANSFER')
        ]).pipe(
            filter(() => Boolean(paymentType)),
            map(([textTitle, textAccount, textCard, textWellsFargo, textBankTransfer]) => {
                const paymentTypeMap: Record<string, string> = {
                    ACCOUNT: textAccount,
                    CARD: textCard,
                    WELLS_FARGO: textWellsFargo,
                    BANK_TRANSFER: textBankTransfer,
                };
                const paymentTypeName = paymentTypeMap[paymentType.code] || '';
                return {
                    title: textTitle,
                    text: [paymentTypeName],
                };
            })
        );
    }

    getCostCenterCardContent(costCenter: CostCenter): Observable<Card> {
        return this.translation.translate('orderDetails.costCenter').pipe(
            filter(() => Boolean(costCenter)),
            map((textTitle) => ({
                title: textTitle,
                textBold: costCenter?.name,
                text: ['(' + costCenter?.unit?.name + ')'],
            }))
        );
    }

    getAddressCardContent(deliveryAddress: Address): Observable<Card> {
      const deliveryTranslation$ = this.isSubscription ?
        this.translation.translate('orderDetails.deliveryAddress') :
        this.translation.translate('orderDetails.shipTo');

        return deliveryTranslation$.pipe(
            filter(() => Boolean(deliveryAddress)),
            map((textTitle) => {
              return {
                title: textTitle,
                text: [
                  deliveryAddress.companyName,
                  concatStrings(
                    ' ',
                    deliveryAddress?.firstName,
                    deliveryAddress?.lastName
                  ),
                  deliveryAddress.line1,
                  deliveryAddress.line2,
                  concatStrings(
                    ', ',
                    deliveryAddress.town,
                    deliveryAddress.region?.name,
                    deliveryAddress?.country?.isocode
                  ),
                  deliveryAddress.postalCode,
                  deliveryAddress.phone,
                ],
              } as Card;
            })
        );
    }

    getFreightForwarderAddressCardContent(freightForwarderAddress: Address): Observable<Card> {
      return this.translation.translate('orderDetails.freightForwarderAddress').pipe(
        filter(() => Boolean(freightForwarderAddress)),
        map((textTitle) => {
          return {
            title: textTitle,
            text: [
              freightForwarderAddress.companyName,
              freightForwarderAddress.firstName,
              freightForwarderAddress.line1,
              freightForwarderAddress.line2,
              concatStrings(
                ', ',
                freightForwarderAddress.town,
                freightForwarderAddress.region?.name,
                freightForwarderAddress?.country?.isocode
              ),
              freightForwarderAddress.postalCode,
              freightForwarderAddress.phone
            ],
          } as Card;
        })
      );
    }

    getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
        return this.translation.translate('orderDetails.shippingMethod').pipe(
            filter(() => Boolean(deliveryMode)),
            map(
                (textTitle) =>
                    ({
                        title: textTitle,
                        textBold: deliveryMode.name,
                        text: [
                            deliveryMode.deliveryCost?.formattedValue
                                ? deliveryMode.deliveryCost?.formattedValue
                                : '',
                        ],
                    } as Card)
            ),
        );
    }

    getPaymentInfoCardContent(payment: PaymentDetails): Observable<Card> {
        return combineLatest([
            this.translation.translate('paymentForm.payment'),
            this.translation.translate('paymentCard.expires', {
                month: Boolean(payment) ? payment.expiryMonth : '',
                year: Boolean(payment) ? payment.expiryYear : '',
            }),
        ]).pipe(
            filter(() => Boolean(payment)),
            map(
                ([textTitle, textExpires]) =>
                    ({
                        title: textTitle,
                        textBold: payment.accountHolderName,
                        text: [payment.cardNumber, textExpires],
                    } as Card)
            )
        );
    }

    getEndDateCard(endDate: string): Observable<Card> {
        endDate = new Date(endDate).toISOString().split('T')[0];
        return combineLatest([
            this.translation.translate('checkoutReview.endDate'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: [endDate]
                } as Card;
            })
        );
    }

    getDesiredDateCard(desiredDate: string): Observable<Card> {
        const formattedDate = moment(desiredDate).format('YYYY-MM-DD');
        return combineLatest([
            this.translation.translate('checkoutReview.desiredDate'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: [formattedDate]
                } as Card;
            })
        );
    }

    getFrequencyCard(frequency: string): Observable<Card> {
        return combineLatest([
            this.translation.translate('checkoutReview.frequency'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: [`Every ${frequency} days`]
                } as Card;
            })
        );
    }

    getNextOrderCard(replenishment: any): Observable<Card> {
        return combineLatest([
            this.translation.translate('checkoutReview.nextOrder'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: [this.addDays(new Date(replenishment.startDate), replenishment.interval).toISOString().split('T')[0]]
                } as Card;
            })
        );
    }

    getSpecialHandlingCard(handlingOptions: HandlingOption[]): Observable<Card> {
        return combineLatest([
            this.translation.translate('checkoutProgress.specialHandlingTab'),
        ]).pipe(
            map(([textTitle]) => {
                return {
                    title: textTitle,
                    text: handlingOptions.map(option => option.name),
                } as Card;
            })
        );
    }

    private addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
      }

    private normalizeFormattedAddress(formattedAddress: string): string {
        const addresses = formattedAddress
            .split(',')
            .map((address) => address.trim());
        return addresses.filter(Boolean).join(', ');
    }
}
