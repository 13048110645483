<form [formGroup]="addToCartForm" (submit)="addToCart()">
    <div class="row favorites-block">
        <div class="stock-info" *ngIf="!isSavedCart && stockBadge">
            <span class="info"
                [class.non-purchasable]="!purchasable"
                [ngStyle]="{'color': stockBadge.color, 'background-color': stockBadge.color + '0D'}"
            >
                <img [src]="stockBadge.image?.url | baseSite" [alt]="stockBadge.name"/>
                <span *ngIf="showInventory$ | async">{{ getInventory() }}</span>
                <span>{{stockBadge?.name}}</span>
            </span>
        </div>
        <ng-container *ngIf="product && !isSavedCart && !isSubs">
            <add-to-favorites
                [product]="product"
                [gaListName]="gaListName"
                [gaProductCategories]="gaProductCategories"
                [class.border-grey]="isIcon && product.erpStatus !== 'DISCONTINUED'"
            ></add-to-favorites>
            <generac-product-badge [badge]="marketingBadge"></generac-product-badge>
            <div class="product-ltl" *ngIf="product.ltl">
                <ng-container *ngIf="(tooltipLtlMessage$ | async) as tooltipLtlMessage">
                    <div class="position-relative" (mouseenter)="ltlMessage=tooltipLtlMessage"
                    (mouseleave)="ltlMessage=''">
                        <img src="../../../../../assets/icons/truck_icon_24.svg">
                        <cx-info-message [message]="ltlMessage" [position]="'position-right'"></cx-info-message>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="quantity-add-to-cart-block">
        <div *ngIf="showQuantity" class="quantity">
            <label *ngIf="purchasable">{{ 'addToCart.quantity' | cxTranslate }}</label>
            <div class="cx-counter-stock">
                <cx-custom-item-counter
                        *ngIf="productCode && purchasable"
                        [min]="+minQuantity"
                        [step]="+addToCartQtyMultiplier"
                        [max]="+maxQuantity"
                        [control]="quantityControl"
                        [productCode]="productCode"
                ></cx-custom-item-counter>

                <div *ngIf="!purchasable"></div>
            </div>
        </div>
        <div
            *ngIf="!purchasable && (component.data$ | async)?.otherProperties?.shouldUseCallForPricingTooltip"
            class="call-for-price"
            (mouseenter)="callForPricingMessage=tooltipMessage"
            (mouseleave)="callForPricingMessage=''"
        >
            <cx-icon [type]="iconTypes.HEADSET" class="regular-icon active"></cx-icon>
            <a [href]="contactForPricingHref">Contact For Pricing</a>
            <cx-info-message [message]="callForPricingMessage" [position]="'position-left'"></cx-info-message>
        </div>
        <button
            *ngIf="productCode && purchasable"
            [ngClass]="
      options?.displayAddToCart
        ? 'link cx-action-link'
        : 'btn btn-primary btn-block'
    "
            [class.is-icon]="isIcon"
            [class.is-mobile-btn]="isMobileBtn"
            [class.add-to-cart]="isAddModal"
            type="submit"
            [disabled]="quantity <= 0 || quantity > maxQuantity"
    >
        <span *ngIf="isSubs && !isAddModal">{{ 'addToCart.buy' | cxTranslate }}</span>
        <div *ngIf="isAddModal">
            <img src="../../../../../../assets/icons/shopping_cart_orange.svg">
            <span>{{ 'addToCart.add' | cxTranslate }}</span>
        </div>
        <span *ngIf="!isAddModal && !isSubs && (!isIcon || isMobileBtn)">{{ options?.addToCartString || ('addToCart.addToCart' | cxTranslate) }}</span>
        <span *ngIf="!isAddModal && !isSubs && (isIcon || isMobileBtn)">
            <img src="../../../../../../assets/icons/shopping_cart_orange.svg">
        </span>
    </button>
    </div>
</form>

