/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { ProductSummaryComponent } from './product-summary.component';
import { OutletModule, SpinnerModule } from '@spartacus/storefront';
import { NoReturnWarningModule } from '../components/no-return-warning/no-return-warning.module';
import { SafeModule } from "../../../../shared/pipes/safe/safe.module";

@NgModule({
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    SpinnerModule,
    NoReturnWarningModule,
    SafeModule
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: ProductSummaryComponent,
        },
      },
    }),
  ],
  declarations: [ProductSummaryComponent],
  exports: [ProductSummaryComponent],
})
export class ProductSummaryModule {}
