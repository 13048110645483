<div *ngIf="savedAddress" class="saved-address-item-wrap">
    <div class="saved-address-item-info">
        <p>
            <span class="field-name">{{ 'savedAddress.company' | cxTranslate}}: </span>
            <span class="field-value">{{ savedAddress.companyName}}</span>
        </p>
        <p>
            <span class="field-name">{{ 'savedAddress.name' | cxTranslate}}: </span>
            <span class="field-value">{{ savedAddress.firstName + ' ' + savedAddress.lastName}}</span>
        </p>
        <p>
            <span class="field-name">{{ 'savedAddress.address' | cxTranslate}}: </span>
            <span class="field-value">{{ savedAddress.formattedAddress}}</span>
        </p>
        <p>
            <span class="field-name">{{ 'savedAddress.phone' | cxTranslate}}: </span>
            <span class="field-value">{{ savedAddress.phone}}</span>
        </p>
    </div>
    <div class="saved-address-item-actions">
        <button (click)="openEditSavedAddressModal(savedAddress)" [disabled]="isUserRestricted">
            {{ 'savedAddress.editAddress' | cxTranslate}}
        </button>
        <button (click)="openDeleteSavedAddressModal(savedAddress?.id)" [disabled]="isUserRestricted">
            {{ 'savedAddress.deleteAddress' | cxTranslate}}
        </button>
    </div>
</div>