<ng-container *ngIf="components$ | async as components">
    <div role="region" tabindex="-1" [attr.aria-label]="ariaLabel | cxTranslate"
        class="container mob-tab-paragraph-container">
        <ng-container *ngFor="let component of components; let i = index">
            <ng-container *ngIf="component">
                <button [class.active]="i === activeTabNum" (click)="select(i, $event)"
                    [attr.aria-expanded]="i === activeTabNum">
                    {{
                    component.title | cxTranslate: {param: tabTitleParams[i] | async}
                    }}

                    <span class="accordion-icon" aria-hidden="true"></span>
                </button>

                <div [class.active]="i === activeTabNum" class="cx-tab-paragraph-content" tabindex="0"
                    role="presentation">
                    <ng-template [cxOutlet]="component.flexType" [cxOutletContext]="{}">
                        <ng-container [cxComponentWrapper]="component"
                            (cxComponentRef)="tabCompLoaded($event)"></ng-container>
                    </ng-template>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="desktop-tab-paragraph-container container">
        <ul class="desktop-tab-paragraph-tabs">
            <li *ngFor="let component of components; let i = index" class="desktop-tab-paragraph-tab">
                <ng-container *ngIf="component">
                    <input [id]="i" type="radio" name="tabs" [value]="i" />
                    <label [class.active]="activeTabNum === i" (click)="activeTabNum = i" [for]="i" role="tab">
                        {{component?.title | cxTranslate}}
                        <ng-container *ngIf="component.uid == 'ApplicableProductsComponent' && applicableProductsInfoMsg">
                            <img
                                 src="../../../../../assets/icons/info-grey.svg"
                                (mouseenter)="applicableProductsTooltip=applicableProductsInfoMsg"
                                (mouseleave)="applicableProductsTooltip=''" />
                            <cx-info-message [message]="applicableProductsTooltip"
                                [position]="'position-right'"></cx-info-message>
                        </ng-container>
                    </label>
                </ng-container>
            </li>
        </ul>

        <div *ngIf="components[activeTabNum]" class="desktop-tab-paragraph-content">
            <ng-template [cxOutlet]="components[activeTabNum].flexType" [cxOutletContext]="{}">
                <ng-container [cxComponentWrapper]="components[activeTabNum]"
                    (cxComponentRef)="tabCompLoaded($event)"></ng-container>
            </ng-template>
        </div>
    </div>
</ng-container>