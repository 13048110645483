<div class="cx-page" *ngIf="model$ | async as model">
  <section
    tabindex="-1"
    class="cx-page-section"
    [attr.aria-label]="'productList.productListResults' | cxTranslate"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12" [ngClass]="{'sp-plp-table': isServicePartsEnabled}" *ngIf="viewMode$ | async as viewMode">
          <div class="sp-plp-tabs" *ngIf="isServicePartsEnabled && isSearchPage">
            <div class="desktop-tab-paragraph-container">
              <ul cxHorizontalScrollOverflowHide class="desktop-tab-paragraph-tabs">
                  <ng-container *ngFor="let tab of searchPageTabs; let i = index">
                      <li class="desktop-tab-paragraph-tab">
                          <a>
                              <input [id]="'tabReference_' + i" type="radio" name="tab-references" [value]="i" />
                              <label [class.active]="activeTabIndex === i" (click)="selectTab(i);" [for]="'tabReference_' + i"
                                  role="tab">{{ tab.name }}</label>
                          </a>
                      </li>
                  </ng-container>
              </ul>
          </div>
          </div>
          <div class="cx-sorting top">
            <div class="row">
              <label *ngIf="activeTabIndex == servicePartsTabIndex" class="form-group col-12 col-lg-3 col-xl-4 sp-nested-searchbox-container">
                <input class="sp-nested-searchbox" maxlength="50" value="{{searchTerm}}" 
                  placeholder="{{'common.search' | cxTranslate}}" #nestedSpSearch
                  (keydown.enter)="navigateToSearchWithExtraParams(nestedSpSearch.value)"/>
                <div role="presentation" class="search-icon" (click)="navigateToSearchWithExtraParams(nestedSpSearch.value)">
                  <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
                </div>
              </label>
              <label
              class="form-group cx-sort-dropdown col-12 col-lg-4 mr-auto" [ngClass]="{'sp-sorting': activeTabIndex == servicePartsTabIndex}">
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination?.sort"
                  [ariaLabel]="'productList.sortResults' | cxTranslate"
                  ariaControls="product-results-list"
                  placeholder="{{ 'productList.sortBy' | cxTranslate }}"
                ></cx-sorting>
              </label>
              <div *ngIf="!isInfiniteScroll" class="col-auto ml-auto pagination-block">
                <div
                  class="cx-pagination"
                  [attr.aria-label]="
                    'productList.productSearchPagination' | cxTranslate
                  "
                >
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <div class="col-auto product-view-block" *ngIf="model?.products?.length > 0">
                <cx-product-view
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                ></cx-product-view>
              </div>
            </div>
          </div>
          <div class="product-list-header" *ngIf="viewMode === ViewModes.List">
            <thead>
              <th scope="col" class="product-title">{{ "productList.product" | cxTranslate }}</th>
              <th scope="col" class="product-price">{{ (isServicePartsList ? "productList.priceMslp" : "productList.priceMsrp") | cxTranslate }}</th>
              <th scope="col" class="product-quantity">{{ "productList.quantity" | cxTranslate }}</th>
            </thead>
          </div>
          <div id="product-results-list" class="cx-product-container">
            <!-- Product list when using pagination -->
            <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
              <ng-container *ngIf="viewMode === ViewModes.Grid">
                <div class="row">
                  <cx-product-grid-item
                    *ngFor="let product of model?.products"
                    [product]="product"
                    [gaProductCategories]="gaProductCategories"
                    [gaListName]="gaListName"
                    [phoneNumberOrEmail]="phoneOrEmail"
                    [tooltipMessage]="tooltipMessage"
                    class="col-12 col-sm-6 col-md-4"
                  ></cx-product-grid-item>
                </div>
              </ng-container>

              <ng-container *ngIf="viewMode === ViewModes.List">
                <cx-product-list-item
                  *ngFor="let product of model?.products"
                  [product]="product"
                  [gaProductCategories]="gaProductCategories"
                  [gaListName]="gaListName"
                  [phoneNumberOrEmail]="phoneOrEmail"
                  [tooltipMessage]="tooltipMessage"
                  class="cx-product-search-list"
                ></cx-product-list-item>
              </ng-container>
            </ng-container>

            <div *ngIf="model?.products?.length < 1" class="no-results">
              <img class="no-results-icon" src="../../../../../../assets/icons/no_results.svg"/>
              <span class="no-results-text">{{ 'productList.noResults' | cxTranslate }}</span>
            </div>

            <!-- Product list when using infinite scroll -->
            <ng-template #infiniteScroll>
              <cx-product-scroll
                [scrollConfig]="scrollConfig"
                [model]="model"
                [inputViewMode]="viewMode"
              ></cx-product-scroll>
            </ng-template>
          </div>
          <div class="cx-sorting bottom">
            <div class="row">
              <label
                class="form-group cx-sort-dropdown col-12 col-lg-4 mr-auto"
              >
                <span>{{ 'productList.sortBy' | cxTranslate }}</span>
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination?.sort"
                  [ariaLabel]="'productList.sortResults' | cxTranslate"
                  ariaControls="product-results-list"
                  placeholder="{{ 'productList.sortBy' | cxTranslate }}"
                ></cx-sorting>
              </label>
              <div
                *ngIf="!isInfiniteScroll"
                class="col-auto pagination-bottom"
                [attr.aria-label]="
                  'productList.productSearchPagination' | cxTranslate
                "
              >
                <div class="cx-pagination">
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <div class="col-auto ml-auto ml-lg-0 product-view-block">
                <cx-product-view
                  *ngIf="model?.products?.length > 0"
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                ></cx-product-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
