import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";

@Component({
  selector: 'cx-info-price',
  templateUrl: './info-price.component.html',
  styleUrls: ['./info-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPriceComponent {
  @Input() isOpen = false;
  @Input() isReady = false;
  @Input() isLoading = true;
  @Input() netPrice: string;
  @Input() msrpPrice: string;
  @Input() currency: string;
  @Input() isServicePart: boolean = false;
  iconTypes = ICON_TYPE;
}
