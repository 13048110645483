<div *ngIf="product" class="dealer-acquisition-product">
    <a
            [routerLink]="product?.routerLink || { cxRoute: 'product', params: product } | cxUrl"
            class="dealer-acquisition-product-image-container"
            tabindex="-1"
    >
        <cx-media
                [container]="product.images?.PRIMARY"
                format="product"
                [alt]="product.summary"
        ></cx-media>
    </a>
    <div class="dealer-acquisition-product-info">
        <a
                [routerLink]="product.routerLink ? product.routerLink : ({ cxRoute: 'product', params: product } | cxUrl)"
                class="dealer-acquisition-product-name"
                [innerHTML]="product.sapMaterialNumber +  ' - ' + product.name"
        ></a>
        <div *ngIf="product.purchasable" class="dealer-acquisition-product-price">
            <div
                    class="dealer-acquisition-product-price-container"
                    [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
                    [class.mb-0]="product.bundleAvailable"
            >
                {{ product.price?.formattedValue }}
                <span class="price-label">MSRP</span>
                <cx-icon
                        (cxClickOutside)="isOpen = false"
                        class="regular-icon grey"
                        [type]="iconTypes.INFO"
                        (click)="getPrice(product)"
                ></cx-icon>
                <cx-info-price
                        [isOpen]="isOpen"
                        [isReady]="isReady"
                        [netPrice]="netPrice"
                        [msrpPrice]="product?.price?.formattedValue"
                        [isServicePart]="product?.servicePart"
                ></cx-info-price>
            </div>
            <a
                    *ngIf="product.bundleAvailable"
                    [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
                    class="buy-in-bundle call-for-pricing link cx-action-btn-link">
                Buy In Bundle >
            </a>
        </div>
        <div *ngIf="!product.purchasable" class="cx-product-price-container non-price">
            <div class="contact-us">
                Please contact us for pricing details.
            </div>
        </div>

        <cx-add-to-cart
                *ngIf="product.purchasable"
                [product]="product"
                [purchasable]="true"
                [productCode]="product.code"
                [showQuantity]="false"
        ></cx-add-to-cart>

        <div *ngIf="!product.purchasable">
            <div
                    class="position-relative"
                    (mouseenter)="callForPricingMessage=tooltipMessage"
                    (mouseleave)="callForPricingMessage=''">
                <a
                        [href]="contactForPricingHref"
                        class="call-for-pricing link cx-action-btn-link">
                    Contact For Pricing
                    <cx-info-message
                            [message]="callForPricingMessage"
                            [position]="'position-left'"
                    ></cx-info-message>
                </a>
            </div>
        </div>
    </div>
</div>