import { Component } from '@angular/core';
import { CmsService, Product, ProductScope, ProductService } from "@spartacus/core";
import { combineLatest, merge, Observable, Subject, Subscription } from "rxjs";
import { CmsProductCarouselComponent as model } from "@spartacus/core/src/model/cms.model";
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { CmsComponentData } from "@spartacus/storefront";
import { CustomerService } from "../../../services/customer.service";
import { ActivatedRoute } from "@angular/router";
import { isEqual } from "../../../shared/helpers/is-equal";

@Component({
  selector: 'cx-dealer-acquisition-products',
  templateUrl: './dealer-acquisition-products.component.html',
  styleUrls: ['./dealer-acquisition-products.component.scss']
})
export class DealerAcquisitionProductsComponent {
  componentData$: Observable<model> = this.componentData.data$.pipe(
    filter((data) => Boolean(data))
  );

  title$: Observable<string | undefined> = this.componentData$.pipe(
    map((data) => data.title)
  );

  products$: Observable<Product[] | undefined> =
    this.componentData$.pipe(
      map((data) => data.productCodes?.trim().split(' ') ?? []),
      distinctUntilChanged(isEqual),
      switchMap((codes) => combineLatest(codes.map((code: string) => this.productService.get(code))))
    );

  private subscriptions = new Subscription();

  constructor(
    protected componentData: CmsComponentData<model>,
    protected productService: ProductService,
    protected cmsService: CmsService,
    protected customerService: CustomerService,
    protected activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
    merge(
      this.componentData.data$,
      this.customerService.getB2bUnitChanged().pipe(map(() => this.cmsService.refreshComponent(this.componentData.uid)))
    )
      .pipe(filter(Boolean))
      .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
