import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CmsService, CMSTabParagraphContainer, WindowRef } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { CmsComponentData, ComponentWrapperDirective, CurrentProductService } from "@spartacus/storefront";
import { ApplicableProductsResponse } from 'src/app/interfaces/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'cx-tab-paragraph-container',
  templateUrl: './tab-paragraph-container.component.html',
  styleUrls: ['./tab-paragraph-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabParagraphContainerComponent implements AfterViewInit, OnInit {
  activeTabNum = 0;
  ariaLabel: string;

  @ViewChildren(ComponentWrapperDirective)
  children!: QueryList<ComponentWrapperDirective>;

  tabTitleParams: (Observable<any> | null)[] = [];

  showApplicableProducts: boolean = false;
  isCurrentProductWarranty: boolean;
  applicableProductsInfoMsg: string = '';
  applicableProductsTooltip: string = '';


  constructor(
    public componentData: CmsComponentData<CMSTabParagraphContainer>,
    protected cmsService: CmsService,
    protected winRef: WindowRef,
    protected currentProductService: CurrentProductService,
    protected productService: ProductService,
  ) {}

  components$: Observable<any[]> = this.currentProductService.getProduct().pipe(
    distinctUntilChanged(),
    switchMap(product => {
      this.isCurrentProductWarranty = product.categories?.some((category: any) => category.code == 'extended-warranties');
      return this.productService.getApplicableProducts(product?.code, 0).pipe(
        take(1),
        switchMap((applicableProducts: ApplicableProductsResponse) => {
            this.showApplicableProducts = applicableProducts?.results.length > 0;
            return this.componentData.data$.pipe(
              distinctUntilChanged((x, y) => x?.components === y?.components),
              take(1),
              tap((data: CMSTabParagraphContainer) => {
                this.ariaLabel = `${data?.uid}.tabPanelContainerRegion`;
              }),
              switchMap((data) =>
                combineLatest(
                  (data?.components ?? '').split(' ').map((component) =>
                    this.cmsService.getComponentData<any>(component).pipe(
                      distinctUntilChanged(),
                      take(1),
                      map((tab) => {
                        if (!tab || (tab.uid == 'ApplicableProductsComponent' && !this.showApplicableProducts)) {
                          return undefined;
                        }
          
                        if (!tab.flexType) {
                          tab = {
                            ...tab,
                            flexType: tab.typeCode,
                          };
                        }

                        const title = (tab.uid == 'ApplicableProductsComponent' && this.isCurrentProductWarranty) ? 'Warranty' + tab.uid : tab.uid;

                        if(tab.uid == 'ApplicableProductsComponent') {
                          this.applicableProductsInfoMsg = this.isCurrentProductWarranty ? tab.warrantyTooltipMessage : tab.tooltipMessage; 
                        }

                        return {
                          ...tab,
                          title: `${data.uid}.tabs.${title}`,
                        };
                      })
                    )
                  )
                )
              )
            );
        })
      )
    })
  )

  select(tabNum: number, event?: MouseEvent): void {
    this.activeTabNum = this.activeTabNum === tabNum ? -1 : tabNum;
    if (event && event?.target) {
      const target = event.target as HTMLElement;
      const parentNode = target.parentNode as HTMLElement;
      this.winRef?.nativeWindow?.scrollTo({
        left: 0,
        top: parentNode.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  ngOnInit(): void {
    this.activeTabNum = this.winRef?.nativeWindow?.history?.state?.activeTab ?? this.activeTabNum;
  }

  ngAfterViewInit(): void {
    // If the sub cms components data exist, the components created before ngAfterViewInit are called.
    // In this case, the title parameters are directly pulled from them.
    if (this.children.length > 0) {
      this.getTitleParams(this.children);
    }
  }

  tabCompLoaded(componentRef: any): void {
    this.tabTitleParams.push(componentRef.instance.tabTitleParam$);
  }

  protected getTitleParams(children: QueryList<ComponentWrapperDirective>) {
    children.forEach((comp) => {
      this.tabTitleParams.push(comp['cmpRef']?.instance.tabTitleParam$ ?? null);
    });
  }
}
