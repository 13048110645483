import { Component, OnInit } from '@angular/core';
import { Address } from '@spartacus/core';
import { FocusConfig, ICON_TYPE } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { CustomLaunchDialogService } from 'src/app/services/custom-launch-dialog.service';

@Component({
  selector: 'cx-verify-address',
  templateUrl: './verify-address.component.html',
})
export class VerifyAddressComponent implements OnInit {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  iconTypes = ICON_TYPE;
  selectedIndex: number = undefined;
  suggestedAddresses: any;
  currentAddress: Address;
  errorMessage: string;
  hideErrorDescription: boolean = false;
  protected subscription = new Subscription();

  constructor(
    protected launchDialogService: CustomLaunchDialogService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
          (dialogData: any) => {
            this.suggestedAddresses = dialogData?.suggested;
            this.currentAddress = dialogData?.current;
            this.errorMessage = dialogData?.error;
          }
      )
    );
  }

  dismiss(reason?: any): void {
    if (!reason && this.selectedIndex !== null && this.selectedIndex > -1) {
      const selected = this.suggestedAddresses[this.selectedIndex];
      reason = {
        ...this.currentAddress,
        line1: selected.line1,
        line2: selected.line2,
        town: selected.town,
        region: { isocode: `${selected.country.isocode}-${selected.region.isocode}` },
        postalCode: selected.postalCode,
      } as Address;
    } else if (!reason && this.selectedIndex === null) {
      reason = this.currentAddress;
    }
    this.launchDialogService.closeDialog(reason, 'VERIFY_ADDRESS');
  }
}
