import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule, MediaModule, SpinnerModule } from "@spartacus/storefront";
import { BundleCarouselModule } from "../bundle-carousel/bundle-carousel.module";
import { ConfigModule, I18nModule, UrlModule } from "@spartacus/core";
import { BundleCarouselComponent } from "../bundle-carousel/bundle-carousel.component";
import { DealerAcquisitionProductsComponent } from './dealer-acquisition-products/dealer-acquisition-products.component';
import { DealerAcquisitionProductComponent } from './dealer-acquisition-products/dealer-acquisition-product/dealer-acquisition-product.component';
import {
  AddToFavoritesComponent
} from "../../spartacus/features/favorites/add-to-favorites/add-to-favorites.component";
import {
  CustomProductAddToCartModule
} from "../../spartacus/features/cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module";
import { InfoMessageModule } from "../../shared/components/info-message/info-message.module";
import { InfoPriceModule } from "../../spartacus/features/product/components/info-price/info-price.module";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [
    DealerAcquisitionProductsComponent,
    DealerAcquisitionProductComponent
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    BundleCarouselModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BundleHomepageCarouselComponent: {
          component: BundleCarouselComponent,
        },
        GeneracDealerAcquisitionProductsCarouselComponent: {
          component: DealerAcquisitionProductsComponent,
        },
      },
    }),
    MediaModule,
    UrlModule,
    AddToFavoritesComponent,
    CustomProductAddToCartModule,
    I18nModule,
    IconModule,
    InfoMessageModule,
    InfoPriceModule,
    SharedModule
  ],
})
export class HomeModule { }
