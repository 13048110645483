<ng-container *ngIf="warranty">
    <a [href]="warrantyHref">
        <cx-media
                class="warranty-image"
                [container]="warranty.images?.PRIMARY"
                format="product"
                [alt]="warranty.name || warranty.summary"
        ></cx-media>
    </a>
    <div class="description">
        <a [href]="warrantyHref">
            <span class="name">{{ warranty.name }}</span>
        </a>
        <div class="price" *ngIf="warranty.purchasable">
            {{ warranty.price?.formattedValue }}
            <span class="price-label">MSRP</span>
            <cx-icon
                    (cxClickOutside)="isOpen = false"
                    class="regular-icon grey"
                    [type]="iconTypes.INFO"
                    (click)="getPrice(warranty)"
            ></cx-icon>
            <cx-info-price
                    *ngIf="warranty.purchasable"
                    [isOpen]="isOpen"
                    [isReady]="isReady"
                    [netPrice]="netPrice"
                    [msrpPrice]="warranty.price?.formattedValue"
            ></cx-info-price>
        </div>
        <div *ngIf="!warranty.purchasable" class="cx-product-price-container non-price">
            <div
                    class="position-relative"
                    (mouseenter)="callForPricingMessage=tooltipMessage"
                    (mouseleave)="callForPricingMessage=''">
                <a
                        [href]="contactForPricingHref"
                        class="call-for-pricing link cx-action-btn-link">
                    Contact For Pricing
                    <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
                </a>
            </div>
        </div>
    </div>
    <cx-add-to-cart
            *ngIf="warranty.purchasable"
            [product]="warranty"
            [purchasable]="true"
            [productCode]="warranty.code"
            [showQuantity]="false" [gaListName]="gaListName" [isIcon]="true"></cx-add-to-cart>
</ng-container>