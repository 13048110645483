import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, AddressValidation, Country, GlobalMessageService, GlobalMessageType, Region, TranslationService, UserAddressService, UserService } from '@spartacus/core';
import { AddressFormComponent, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, of } from 'rxjs';
import { filter, first, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AddressValidationService } from '../../../../services/address-validation.service';
import { AddressType } from 'src/app/enums/address-type.enum';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { ExtAddress, ValidationAddress } from 'src/app/interfaces/checkout';
import { UserGroup } from 'src/app/enums/user.enum';

@Component({
  selector: 'generac-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracAddressFormComponent extends AddressFormComponent implements OnInit, OnDestroy {
  @Input()
  fromCheckout = false;

  @Input()
  isEditable: boolean = false;

  @Input()
  addressType: AddressType;

  _addressData: Address;
  get generacAddressData(): Address {
    return this._addressData;
  }
  @Input() set generacAddressData(value: Address) {
    this._addressData = value;
    if (value && Object.keys(value).length !== 0) {
      this.addressForm.patchValue(value);
      this.showSubmitBtn = false;

      this.countrySelected(value.country);
      if (value.region) {
        this.regionSelected(value.region);
      }
    }
  }

  @Input()
  showSubmitBtn = true;

  _disableForm: boolean = false;
  get disableForm(): boolean {
    return this._disableForm;
  }
  @Input() set disableForm(value: boolean) {
    this._disableForm = value;
    if (value) {
      this.disableFormControls();
      const regionControl = this.addressForm.get('region.isocode');
      regionControl.disable();
    }
  }

  @Input() editSavedAddressMode: boolean = false;

  @Output() saveDropShipAddressChanged = new EventEmitter<boolean>();

  @Output() limitCountOfAddressesMessage = new EventEmitter<string>();

  AddressType = AddressType;
  formSubscription: Subscription = new Subscription();

  dropShipAddresses$: Observable<ExtAddress[]>;
  dropShipAddresses: ExtAddress[];
  saveDropShipAddress: boolean;
  selectedDropShipAddress: string;
  isUserRestricted: boolean = false;

  override addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    titleCode: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: ['', Validators.required],
    line1: ['', Validators.required],
    line2: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: ['', Validators.required],
    phone: '',
    cellphone: '',
    defaultAddress: [false],
  });

  constructor(
    override fb: UntypedFormBuilder,
    override userService: UserService,
    override userAddressService: UserAddressService,
    override globalMessageService: GlobalMessageService,
    override translation: TranslationService,
    override launchDialogService: LaunchDialogService,
    protected addressValidationService: AddressValidationService,
    protected vcr: ViewContainerRef,
    protected checkoutService: CheckoutService,
    private userAccount: UserAccountFacade,
    private validationService: AddressValidationService,
  ) {
    super(fb, userService, userAddressService, globalMessageService, translation, launchDialogService);
  }

  override ngOnInit() {
    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      })
    );

    this.dropShipAddresses$ = this.userAccount.get().pipe(
      filter(() => this.addressType == AddressType.DROP_SHIP),
      take(1),
      tap((user) => this.isUserRestricted = user?.roles.includes(UserGroup.savedaddresschangerestrictedgroup)),
      switchMap((user) => this.checkoutService.getDropShipAddresses(user?.uid)),
      tap((res) => this.limitCountOfAddressesMessage.emit(res?.message ? res?.message : null)),
      switchMap((res: any) => of(res.addresses)),
      tap((addresses: ExtAddress[]) => this.dropShipAddresses = addresses),
      tap(() => this.dropShipAddressSelected(this.addressForm.getRawValue())),
      tap(() => this.setDropShipFields()),
    );

    // Fetching titles
    this.titles$ = this.getTitles();

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0 && !this.disableForm) {
          regionControl?.enable();
        } else {
          regionControl?.disable();
        }
      })
    );

    this.addresses$ = this.userAddressService.getAddresses();
    this.setAddressModified();
  }

  getDropShipAddressIdByFormData(formData: ExtAddress): string {
    return this.dropShipAddresses.find((dropShipAddress: ExtAddress) => {
      return dropShipAddress.companyName == formData.companyName &&
        dropShipAddress.firstName == formData.firstName &&
        dropShipAddress.lastName == formData.lastName &&
        dropShipAddress.country.isocode == formData.country.isocode &&
        dropShipAddress.line1 == formData.line1 &&
        dropShipAddress.town == formData.town &&
        dropShipAddress.region.isocode == formData.region.isocode &&
        dropShipAddress.postalCode == formData.postalCode &&
        (dropShipAddress.line2 == formData.line2 || ((!dropShipAddress.line2 || dropShipAddress.line2 == '') && formData.line2 == '')) &&
        (dropShipAddress.phone == formData.phone || ((!dropShipAddress.phone || dropShipAddress.phone == '') && formData.phone == ''));
    })?.id;
  }

  validateForm(form: FormGroup) {
    if (!form.valid) {
      const elements = document.getElementsByClassName('control-invalid');
      elements.length && elements[0].previousElementSibling ? elements[0].previousElementSibling.scrollIntoView() : '';
    }
  }

  override handleAddressVerificationResults(results: AddressValidation) {
    this.openSuggestedAddress();
  }

  override openSuggestedAddress() {
    this.subscription.add(
      this.validationService
        .validate(this.addressForm?.getRawValue())
        .pipe(
          first(),
          tap(res => {
            if (res.error?.description?.length > 0) {
              this.globalMessageService.add({ key: res.error.description }, GlobalMessageType.MSG_TYPE_ERROR);

            }
          }),
          tap((res: ValidationAddress) => {
            this.launchDialogService.openDialogAndSubscribe(
              'VERIFY_ADDRESS',
              undefined,
              { suggested: res.suggested, current: this.addressForm?.getRawValue(), error: res.error?.description }
            );
            this.subscription.add(
              this.launchDialogService.dialogClose
                .pipe(
                  filter(data => !!data),
                  tap((data: any) => {
                    if(data?.line1) {
                      this.addressForm.patchValue(data);
                      this.showSubmitBtn = false;
                    } else {
                      this.showSubmitBtn = true;
                    }
                    this.submitAddress.emit(data?.line1 ? this.addressForm : null);
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );

  }

  setAddressModified() {
    this.formSubscription.add(
      this.addressForm.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, cur]) => {
          if (this.editSavedAddressMode) {
            this.actionBtnLabel = 'Validate';
            this.showSubmitBtn = false;
          };
          if (this.addressForm.dirty && JSON.stringify(prev) != JSON.stringify(cur) && this.addressForm.status != "DISABLED" && this.addressForm.status != "INVALID") {
            this.showSubmitBtn = true;
            this.addressValidationService.setDeliveryAddressModified(cur);
            this.setDropShipFields();
            this.submitAddress.emit(null);
          }
        })
    );
  }

  toggleSaveDropShipAddress(): void {
    this.saveDropShipAddressChanged.emit(this.saveDropShipAddress);
  }

  dropShipAddressSelected(dropShipAddress: ExtAddress): void {
    if (dropShipAddress) {
      this.generacAddressData = dropShipAddress;
      this.addressForm.controls['country'].markAsDirty();
      this.showSubmitBtn = true;
    }
  }

  clearDropShipAddress() {
    this.addressForm.reset();
  }

  addressSearchFn(term: string, item: ExtAddress) {
    const loverTerm = term.toLocaleLowerCase();
    return item?.addressType?.name?.toLocaleLowerCase().includes(loverTerm) ||
      item?.companyName?.toLocaleLowerCase().includes(loverTerm) ||
      item?.formattedAddress?.toLocaleLowerCase().includes(loverTerm);
  }

  private setDropShipFields() {
    if (this.dropShipAddresses) {
      const id = this.getDropShipAddressIdByFormData(this.addressForm.getRawValue());
      if (id) {
        this.selectedDropShipAddress = id;
        this.saveDropShipAddress = false;
      } else {
        this.selectedDropShipAddress = null;
      }
      this.showSubmitBtn = true;
    }
  }

  private disableFormControls(): void {
    for (var control in this.addressForm.controls) {
      this.addressForm.controls[control].disable();
    }
  }

  override ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }
}
