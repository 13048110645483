import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ICON_TYPE } from "@spartacus/storefront";
import { MSRPPrice, ProductExtended } from "../../../../interfaces/product";
import { combineLatest, Subscription } from "rxjs";
import { User } from "@spartacus/core";
import { ProductService } from "../../../../services/product.service";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { filter, finalize, take, tap } from "rxjs/operators";
import { BaseStoreService } from "../../../../services/base-store.service";

@Component({
  selector: 'cx-dealer-acquisition-product',
  templateUrl: './dealer-acquisition-product.component.html',
  styleUrls: ['./dealer-acquisition-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerAcquisitionProductComponent implements OnInit, OnDestroy {

  @Input() product: any;

  tooltipMessage: string;
  netPrice: string;
  currency: string;
  isOpen: boolean;
  isReady: boolean;
  callForPricingMessage = '';
  contactForPricingHref: string;
  iconTypes = ICON_TYPE;
  private subscriptions = new Subscription();
  private user: User;

  constructor(
    private productService: ProductService,
    private userAccount: UserAccountFacade,
    private cdr: ChangeDetectorRef,
    private baseStoreService: BaseStoreService,
  ) {
  }

  ngOnInit(): void {
    this.getUser();
    this.getTooltip();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPrice(product: ProductExtended): void {
    this.isOpen = true;
    this.isReady = false;
    this.productService.getCPIPrice(this.user?.uid, product)
      .pipe(
        take(1),
        tap((price: MSRPPrice) => {
          if (price) {
            this.netPrice = price.value;
            this.currency = price.currency;
            this.cdr.markForCheck();
          }
        }),
        finalize(() => {
          this.isReady = true;
          this.cdr.markForCheck();
        })
      )
      .subscribe()
  }

  private getUser(): void {
    this.subscriptions.add(
      this.userAccount.get()
        .pipe(
          filter(Boolean),
          tap(user => this.user = user)
        )
        .subscribe()
    )
  }

  private getTooltip() {
    this.subscriptions.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([message, phoneOrEmail]) => {
        this.tooltipMessage = message?.message;

        if(phoneOrEmail?.contactInfo) {
          this.contactForPricingHref = phoneOrEmail?.contactInfo?.includes('@') ? `mailto: ${phoneOrEmail?.contactInfo}` : `tel: ${phoneOrEmail?.contactInfo}`;
        }
        this.cdr.markForCheck();
      })
    );
  }


}
