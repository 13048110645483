<ng-container *ngIf="product">
  <a
    [routerLink]="product.routerLink ? product.routerLink : { cxRoute: 'product', params: product } | cxUrl"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-image-container"
    [class.disabled-link]="isSubs"
    tabindex="-1"
  >
    <cx-media
      class="cx-product-image"
      [class.sub-non-image]="isSubs && !product.images?.PRIMARY.product"
      [container]="product.images?.PRIMARY"
      format="product"
      [alt]="product.summary"
    ></cx-media>
  </a>
  <a
    *ngIf="!isSubs"
    [routerLink]="product.routerLink ? product.routerLink : ({ cxRoute: 'product', params: product } | cxUrl)"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-name"
    [innerHTML]="product.name"
  ></a>

  <a
    *ngIf="isSubs"
    class="cx-product-name"
    [innerHTML]="product.name"
  ></a>

  <span
    *ngIf="isSubs"
    class="summary"
    [innerHTML]="product.summary"
  ></span>

  <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
    <div *ngIf="product.purchasable || isSubs" class="cx-product-price-container">
      <div
        class="cx-product-price"
        [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
        [class.mb-0]="product.bundleAvailable"
      >
        {{ product.price?.formattedValue }}
          <span *ngIf="!isSubs" class="price-label">{{ product.servicePart ? 'MSLP' : 'MSRP'}}</span>
        <cx-icon
                *ngIf="!isSubs"
                (cxClickOutside)="isOpen = false"
                class="regular-icon grey"
                [type]="iconTypes.INFO"
                (click)="getPrice(product)"
        ></cx-icon>
          <cx-info-price
              *ngIf="!isSubs"
              [isOpen]="isOpen"
              [isReady]="isReady"
              [netPrice]="netPrice"
              [msrpPrice]="product?.price?.formattedValue"
              [isServicePart]="product?.servicePart"
          ></cx-info-price>
      </div>
      <a
        *ngIf="!isSubs && product.bundleAvailable"
        [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
        class="buy-in-bundle call-for-pricing link cx-action-btn-link">
        Buy In Bundle >
      </a>
    </div>
    <div *ngIf="!isSubs && !product.purchasable" class="cx-product-price-container non-price">
      <div class="contact-us">
        Please contact us for pricing details.
      </div>
    </div>
  </ng-template>

  <cx-add-to-cart 
    *ngIf="product?.purchasable"
    [product]="product"
    [purchasable]="true"
    [productCode]="product.code"
    [showQuantity]="false"
    [gaListName]="gaListName"
    [gaProductCategories]="gaProductCategories"
    [isSubs]="isSubs"
    [isAddModal]="isAddModal"
    (onAddModalAdded)="onAddModalAdded.emit($event)"
  ></cx-add-to-cart>

  <div *ngIf="!product.purchasable && !isSubs">
    <add-to-favorites [product]="product" [gaListName]="gaListName" [gaProductCategories]="gaProductCategories"></add-to-favorites>
    <div class="product-ltl" *ngIf="product.ltl">
      <ng-container *ngIf="(tooltipLtlMessage$ | async) as tooltipLtlMessage">
        <div class="position-relative" (mouseenter)="ltlMessage=tooltipLtlMessage"
          (mouseleave)="ltlMessage=''">
            <img src="../../../../../assets/icons/truck_icon_24.svg">
            <cx-info-message [message]="ltlMessage" [position]="'position-right'"></cx-info-message>
        </div>
      </ng-container>
    </div>
    <div
      class="position-relative"
      (mouseenter)="callForPricingMessage=tooltipMessage"
      (mouseleave)="callForPricingMessage=''">
      <a
        [href]="contactForPricingHref"
        class="call-for-pricing link cx-action-btn-link">
          Contact For Pricing
          <cx-info-message [message]="callForPricingMessage" [position]="'position-left'"></cx-info-message>
      </a>
    </div>
  </div>

</ng-container>
