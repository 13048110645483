import { CmsConfig } from "@spartacus/core";
import { GeneracAddressFormComponent } from "../features/address-book/address-form/address-form.component";
import { BundleCarouselComponent } from "src/app/pages/bundle-carousel/bundle-carousel.component";
import { SavedAddressesComponent } from "../features/saved-addresses/saved-addresses.component";
import { GeneracProductFacetNavigationComponent } from "src/app/shared/components/generac-product-facet-navigation/product-facet-navigation.component";

// Created this config to move all components overrides later
export const cmsConfig: CmsConfig = {
  cmsComponents: {
    AddressFormComponent: {
      component: GeneracAddressFormComponent,
    },
    BundleHomepageCarouselComponent: {
      component: BundleCarouselComponent,
    },
    SavedAddressesComponent: {
      component: SavedAddressesComponent,
    },
    ProductRefinementComponent: {
      component: GeneracProductFacetNavigationComponent,
    },
  }
}