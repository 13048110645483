import { NgModule } from '@angular/core';
import { ProductAttributesModule } from './product-attributes/product-attributes.module';
import { ProductDetailsTabModule } from './product-details-tab/product-details-tab.module';
import { ProductReviewsModule } from './product-reviews/product-reviews.module';
import { CombineArraysModule } from "../../../../shared/pipes/combine-arrays/combine-arrays.module";
import { ApplicableProductsModule } from './applicable-products/applicable-products.module';

@NgModule({
  imports: [
    ProductAttributesModule,
    ProductDetailsTabModule,
    ProductReviewsModule,
    ApplicableProductsModule,
    CombineArraysModule
  ],
})
export class ProductTabsModule {}
