
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CmsSearchBoxComponent, ConverterService, LoggerService, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductActions, ProductSearchPage, RoutingService, StateWithProduct, WindowRef, normalizeHttpError } from '@spartacus/core';
import { CmsComponentData, ICON_TYPE, SearchBoxComponent, SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ProductExtended } from 'src/app/interfaces/product';
import { ProductService } from 'src/app/services/product.service';

const SEARCHBOX_IS_ACTIVE = 'searchbox-is-active';

const SP_SEARCH_CRITERIAS = [{
  name: 'Model',
  value: 'model',
}, {
  name: 'Serial Number',
  value: 'serial-number',
}];

const DEFAULT_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: true,
  maxProducts: 5,
  maxSuggestions: 5,
  displayProductImages: true,
};
@Component({
  selector: 'generac-searchbox',
  templateUrl: './search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracSearchBoxComponent extends SearchBoxComponent {
  isServicePartsEnabled: boolean = false;
  servicePartsSearchOpen: boolean = false;
  activeSpSearchCriteria: string = SP_SEARCH_CRITERIAS[0].value;
  selectedSpSearchProduct: ProductExtended;
  spModelSaved: ProductExtended;
  spSearchInitData: ProductSearchPage;
  isMobileSearchBoxShown: boolean;

  @HostListener('window:resize', ['$event'])
  onResizeWindow(event:any) {
    this.isMobileSearchBoxShown = event.target?.innerWidth > 768 - 1 ? false : true;
  }

  readonly ICON_TYPE = ICON_TYPE;
  readonly SP_SEARCH_CRITERIAS = SP_SEARCH_CRITERIAS;

  protected subscriptions = new Subscription();

  constructor(
    override searchBoxComponentService: SearchBoxComponentService,
    @Optional()
    override componentData: CmsComponentData<CmsSearchBoxComponent>,
    override winRef: WindowRef,
    override routingService: RoutingService,
    protected productService: ProductService,
    protected store: Store<StateWithProduct>,
    protected converter: ConverterService,
    protected logger: LoggerService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
    if (this.winRef.nativeWindow?.innerWidth) {
      this.isMobileSearchBoxShown = this.winRef.nativeWindow?.innerWidth > 768 - 1 ? false : true;
    }
    this.subscriptions.add(
      this.activatedRoute.queryParams.pipe(
        tap((queryParams) => {
          this.updateChosenWord(decodeURI(this.winRef.location?.pathname?.split('/')[this.winRef.location?.pathname?.split('/').length - 1] || ''));
          if (this.isServicePartsEnabled && this.winRef.location?.href.indexOf('/search') > -1 && queryParams?.searchBy?.length > 1 && queryParams?.code?.length > 1) {
            this.activeSpSearchCriteria = queryParams.searchBy;
            this.spModelSaved = { code: queryParams.code };
          } else {
            this.spModelSaved = null;
            this.selectedSpSearchProduct = null;
          }
          this.cdr.markForCheck();
        })
      ).subscribe()
    )
  }

  toggleServicePartsSearch(val?: boolean, el?: HTMLInputElement): void {
    if (val === false && this.searchBoxComponentService.hasBodyClass(SEARCHBOX_IS_ACTIVE)) {
      super.clear(el);
      setTimeout(() => {
        el.blur();
        if (this.searchBoxComponentService.hasBodyClass(SEARCHBOX_IS_ACTIVE)) {
          this.searchBoxComponentService.toggleBodyClass(SEARCHBOX_IS_ACTIVE, false);
        }
      });
    }
    if (!this.servicePartsSearchOpen && !this.isMobileSearchBoxShown) {
      this.searchBoxComponentService.toggleBodyClass(SEARCHBOX_IS_ACTIVE, false);
    }
    if (!this.isServicePartsEnabled || (this.spModelSaved && !this.servicePartsSearchOpen)) return;
    this.servicePartsSearchOpen = val !== undefined ? val : !this.servicePartsSearchOpen;
  }

  selectSpSeachTab(value: string): void {
    this.activeSpSearchCriteria = value;
  }

  openProductSearch(): void {
    super.open();
    if (this.isServicePartsEnabled) {
      this.servicePartsSearchOpen = false;
    }
  }

  override search(query: string): void {
    super.open();
    const cleanQuery = query.trim().toLowerCase();
    if (this.spModelSaved && cleanQuery?.length > 0) {
      this.searchSpByModelOrSerialNumber(this.spModelSaved?.code, cleanQuery);
    } else {
      this.searchBoxComponentService.search(query, this.config || DEFAULT_SEARCH_BOX_CONFIG);
    }
  }

  searchSpByModelOrSerialNumber(modelNumber: string, freeTextSearch?: string) {
    this.subscription.add(
      this.productService.searchSpByModelOrSerialNumber(modelNumber, {}, freeTextSearch, true, this.activeSpSearchCriteria)
        .subscribe()
    )
  }

  spSearch(query: string): void {
    if (query?.length < 4 || this.activeSpSearchCriteria !== 'model') return;
    this.subscription.add(
      this.productService.searchSuggestionsByModelNumber(query)
        .pipe(
          take(1),
          this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER),
          tap((data: ProductSearchPage) => {
            this.store.dispatch(
              new ProductActions.SearchProductsSuccess(
                data,
                true
              )
            )
          }),
          tap(() => this.selectedSpSearchProduct = null),
          catchError((error) => {
            this.store.dispatch(
              new ProductActions.SearchProductsFail(
                normalizeHttpError(error, this.logger),
                true
              )
            )
            return of();
          })
        ).subscribe()
    )
  }

  clearSpSearch(el: HTMLInputElement, clearSelectedSpSearchProduct: boolean = true): void {
    super.clear(el);
    if (clearSelectedSpSearchProduct) this.selectedSpSearchProduct = null;
  }

  selectSpSearchProduct(product: ProductExtended): void {
    this.selectedSpSearchProduct = product;
  }

  closeSpSearch(el: HTMLInputElement, clearSelectedSpSearchProduct?: boolean): void {
    this.toggleServicePartsSearch(false, el);
    this.searchBoxComponentService.clearResults();
    this.clearSpSearch(el, clearSelectedSpSearchProduct);
  }

  saveSpModel(el: HTMLInputElement): void {
    this.spModelSaved = this.activeSpSearchCriteria == 'model' ? this.selectedSpSearchProduct : { code: el?.value, name: el?.value };
    this.closeSpSearch(el, false);
    this.navigateToSearchWithExtraParams(this.chosenWord || '');
  }

  navigateToSearchWithExtraParams(freeTextSearch: string) {
    const queryParams = { searchBy: this.spModelSaved?.code && this.activeSpSearchCriteria, code: this.spModelSaved?.code };
    if (freeTextSearch || (!freeTextSearch && queryParams?.searchBy && queryParams?.code)) {
      this.router.navigate([`/search/${freeTextSearch}`], { queryParams });
    }
  }

  clearSpSearchSaved(e: any): void {
    super.disableClose();
    this.selectedSpSearchProduct = null;
    this.spModelSaved = null;
    this.activeSpSearchCriteria = this.SP_SEARCH_CRITERIAS[0].value;
    this.navigateToSearchWithExtraParams(this.chosenWord || '');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions?.unsubscribe();
  }
}
