import { OccConfig } from "@spartacus/core";

export const endpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          details: 'products/${productCode}?fields=categories(FULL,name),purchasable,maxOrderQuantity,minOrderQuantity,addToCartQtyMultiplier,addToCartToolTip,description,bundleAvailable,specSheetLink,displayModelNumber,badges,favorite,erpStatus,displayItemAttentionMessage,price(FULL),warranties(DEFAULT),excludedByRegion,servicePart,materialNotes,ltl,unavailabilityDetail',
        },
        productSearch:
          'products/search?fields=products(code,name,purchasable,summary,configurable,configuratorType,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,badges,favorite,displayItemAttentionMessage,erpStatus,excludedByRegion,bundleAvailable,maxOrderQuantity,minOrderQuantity,addToCartQtyMultiplier,displayModelNumber,servicePart,materialNotes,ltl,unavailabilityDetail,scaleQuantityToPrice(FULL)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery,keywordRedirectUrl',
        savedCarts:
          '/users/${userId}/savedCarts?savedCartsOnly=true&fields=carts(DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),saveTime,user,name,description,cartOwner,entryGroups(FULL))',
        savedCart:
          '/users/${userId}/carts/${cartId}/${cartOwner}/savedcartshared',
        restoreSavedCart:
          '/users/${userId}/carts/${cartId}/${cartOwner}/restoresavedcartshared',
        cloneSavedCart:
          '/users/${userId}/carts/${cartId}/${cartOwner}/clonesavedcartshared?name=${saveCartName}',
        carts: 'users/${userId}/carts?fields=carts(DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL), displayModelNumber),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,dropShipAddress(FULL),deliveryAddress(FULL),freightForwarderAddress(FULL),tradeDiscounts(FULL),promoDiscounts(FULL),unavailableItems(FULL),entryGroups(FULL),orderInstructions,cartTypes)',
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL), displayModelNumber),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,dropShipAddress(FULL),deliveryAddress(FULL),freightForwarderAddress(FULL),tradeDiscounts(FULL),promoDiscounts(FULL),unavailableItems(FULL),entryGroups(FULL),orderInstructions,cartTypes',
        createCart:
          'users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        addEntries: 'users/${userId}/carts/${cartId}/entries',
        updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        removeEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        addEmail: 'users/${userId}/carts/${cartId}/email',
        deleteCart: 'users/${userId}/carts/${cartId}',
        cartVoucher: 'users/${userId}/carts/${cartId}/vouchers',
        saveCart:
          '/users/${userId}/carts/${cartId}/save?saveCartName=${saveCartName}&saveCartDescription=${saveCartDescription}',
        validate: 'users/${userId}/carts/${cartId}/validate?fields=DEFAULT',
      } as any,
    },
  },
}