/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductListItemContextSource } from '../model/product-list-item-context-source.model';
import { ProductListItemContext } from '../model/product-list-item-context.model';
import { ICON_TYPE, ProductListOutlets } from '@spartacus/storefront';
import { MSRPPrice, ProductExtended } from "../../../../../interfaces/product";
import { ProductService } from "../../../../../services/product.service";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { filter, finalize, take, takeUntil, tap } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { TranslationService, User } from "@spartacus/core";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { normalizeSlug } from '../../helpers/slug.helper';

@Component({
  selector: 'cx-product-grid-item',
  templateUrl: './product-grid-item.component.html',
  styleUrls: ['./product-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductGridItemComponent implements OnChanges, OnInit, OnDestroy {
  readonly ProductListOutlets = ProductListOutlets;
  @Input() isSubs = false;
  @Input() isAddModal = false;

  _phoneNumberOrEmail: string;
  get phoneNumberOrEmail(): string {
    return this._phoneNumberOrEmail;
  }
  @Input() set phoneNumberOrEmail(value: string) {
    this.contactForPricingHref = value?.includes('@') ? `mailto: ${value}` : `tel: ${value}`;
    this._phoneNumberOrEmail = value;
  }
  @Input() tooltipMessage: string;
  @Input() gaProductCategories: {name: string}[];
  @Input() gaListName: string;

  netPrice: string;
  currency: string;
  isOpen: boolean;
  isReady: boolean;
  callForPricingMessage = '';
  contactForPricingHref: string;
  iconTypes = ICON_TYPE;
  ltlMessage: string = '';
  tooltipLtlMessage$: Observable<string>;

  _product: any;
  get product(): any {
    return this._product;
  }
  @Input() set product(value: any) {
    if (this.isSubs) {
      const product = value.images?.find((i: any) => i.format === 'product');
      const thumbnail = value.images?.find((i: any) => i.format === 'thumbnail');
      value.images = {
        PRIMARY: {
          product,
          thumbnail
        }
      };
      value.slug = normalizeSlug(value.name);
    }

    this._product = value;
  }

  @Output() addToCart: EventEmitter<ProductExtended> = new EventEmitter();
  @Output() onAddModalAdded = new EventEmitter<{productCode: string, quantity: string}>();

  private destroy$ = new Subject();
  private user: User;

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected translation: TranslationService,
    private productService: ProductService,
    private userAccount: UserAccountFacade,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.product && !this.isSubs) {
      this.productListItemContextSource.product$.next(this.product);
    }
  }

  ngOnInit(): void {
    this.getUser();
    this.tooltipLtlMessage$ = this.translation.translate('productList.tooltipLtlMessage');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getPrice(product: ProductExtended): void {
    this.isOpen = true;
    this.isReady = false;
    this.productService.getCPIPrice(this.user?.uid, product)
        .pipe(
            take(1),
            tap((price: MSRPPrice) => {
              if (price) {
                this.netPrice = price.value;
                this.currency = price.currency;
                this.cdr.markForCheck();
              }
            }),
            finalize(() => {
              this.isReady = true;
              this.cdr.markForCheck();
            })
        )
        .subscribe()
  }

  selectItem(item: ProductExtended): void {
    const product = {
      ...item,
      categories: this.gaProductCategories,
    };
    this.googleAnalyticsService.sendGaEvent('select_item', {
      items: [ this.googleAnalyticsService.mapProductToGaItem(product, 0, undefined, this.gaListName, this.gaListName) ],
      item_list_name: this.gaListName || '',
      item_list_id: this.gaListName ? this.gaListName.toLowerCase().trim().replace(/ /g, '_') : '',
      currency: product.price?.currencyIso,
    });
  }

  private getUser(): void {
    this.userAccount.get()
        .pipe(
            takeUntil(this.destroy$),
            filter(Boolean),
            tap(user => this.user = user)
        )
        .subscribe()
  }
}
