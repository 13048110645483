<div *ngIf="isOpen" class="info-message-container position-left">
    <!-- <cx-icon class="regular-icon active" [type]="iconTypes.INFO"></cx-icon> -->
    <div class="prices">
        <div class="price-net">
            <span *ngIf="netPrice" class="net-label">NET</span>
            <span *ngIf="isReady else loading">{{ netPrice | currency: currency }}</span>
        </div>
        <div *ngIf="msrpPrice" class="price-msrp">
            <span class="msrp-label">{{isServicePart ? 'MSLP' : 'MSRP'}}</span>
            {{ msrpPrice }}
        </div>
        <ng-template #loading>
            <div class="cx-spinner">
                <cx-spinner></cx-spinner>
            </div>
        </ng-template>
    </div>
</div>
