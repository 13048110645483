<ng-container *ngIf="product$ | async as product">
  <ng-template
    [cxOutlet]="outlets.PRICE"
    [cxOutletContext]="{ product: product }"
  >
    <div
      *ngIf="product?.price?.formattedValue"
      class="prices"
    >
        <div
                *ngIf="currentPrice"
                [attr.aria-label]="'productSummary.newItemPrice' | cxTranslate"
                class="price"
        >
          {{ currentPrice.value | currency : currentPrice.currency }}
          <span
            *ngIf="currentPrice.value"
            class="net"
          >
             NET
          </span>
        </div>
        <div *ngIf="!currentPrice && !noNetPrice" class="price">
          <div class="cx-spinner">
            <cx-spinner></cx-spinner>
          </div>
        </div>
      <div class="price-msr">
        <div class="msr-amount">{{ product?.price?.formattedValue }}</div>
        <span>MSRP</span>
      </div>
    </div>
  </ng-template>

  <ng-template
    [cxOutlet]="outlets.SUMMARY"
    [cxOutletContext]="{ product: product }"
  >
    <p
      *ngIf="product?.summary"
      [innerHTML]="product?.summary | safe"
      class="summary"
    ></p>
  </ng-template>

  <!-- @TODO: Temp. Comment out share link while not in use by CMS -->
  <!-- <ng-container *cxOutlet="outlets.SHARE">
        <div>
          <a href="#" class="share btn-link">
            {{ 'productSummary.share' | cxTranslate }}
          </a>
        </div>
      </ng-container> -->

  <cx-no-return-warning [message]="product.displayItemAttentionMessage"></cx-no-return-warning>
  <cx-no-return-warning *ngIf="product.excludedByRegion" [color]="'#F6E276'" [message]="exclusionMessage"></cx-no-return-warning>
</ng-container>
