import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { EventService,  RoutingService } from '@spartacus/core';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Observable, Subscription } from 'rxjs';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { take, tap } from 'rxjs/operators';
import { FavoritesListItem, FavotitesDialogActionModes } from 'src/app/interfaces/favorites.model';

@Component({
  selector: 'create-or-edit-favorites-list-dialog',
  templateUrl: './create-or-edit-favorites-list-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateOrEditFavoritesListDialogComponent {
  private subscription = new Subscription();
  iconTypes = ICON_TYPE;
  userId: string;
  mode: FavotitesDialogActionModes;
  list: FavoritesListItem;
  loading: boolean = false;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    // Close on click outside the dialog window
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.close('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected favoritesService: FavoritesService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (data: any) => {
          this.userId = data.userId;
          this.mode = data.list?.id ? FavotitesDialogActionModes.Edit : FavotitesDialogActionModes.Create;
          this.list = data.list;
        }
      )
    );
  }

  private renameList(listName: string): Observable<FavoritesListItem> {
    return this.favoritesService.renameFavoritesList(this.userId, this.list?.id, listName);
  };

  private createList(listName: string): Observable<FavoritesListItem> {
    return this.favoritesService.createFavoritesList(this.userId, listName);
  };

  public createOrEditFavoritesList(listName: string): Subscription {
    this.loading = true;
    const request = this.mode == FavotitesDialogActionModes.Edit ? this.renameList(listName) : this.createList(listName);
    return request.pipe(
      take(1),
      tap(() => this.loading = false))
      .subscribe(() => {
        this.launchDialogService.closeDialog(this.mode);
      });
  }

  public close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }
}
