import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, combineLatest, of, throwError } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { ConverterService, GlobalMessageService, GlobalMessageType, LoggerService, OccEndpointsService, PRODUCT_NORMALIZER, PRODUCT_SEARCH_PAGE_NORMALIZER, Product, ProductActions, ProductSearchPage, ProductSearchService, SearchConfig, StateWithProduct, normalizeHttpError } from "@spartacus/core";
import { BaseStoreService } from "./base-store.service";
import { ApplicableProductsResponse, ProductExtended } from "../interfaces/product";
import { ErpStatus } from "../enums/erp-status.enum";
import { CurrentProductService } from "@spartacus/storefront";
import { Store } from "@ngrx/store";

@Injectable({
    providedIn: "root"
})
export class ProductService {
    cpiPrice$: Observable<{
        price: any,
        code: string;
    } | any>;
    quantityMap: Map<string, number> = new Map();
    cpiPriceRes$$ = new BehaviorSubject(null);
    constructor(
        private httpClient: HttpClient,
        private globalMessageService: GlobalMessageService,
        private baseStoreService: BaseStoreService,
        private occEndpoints: OccEndpointsService,
        private currentProductService: CurrentProductService,
        private converter: ConverterService,
        protected logger: LoggerService,
        protected store: Store<StateWithProduct>,
        protected productSearchService: ProductSearchService,
    ) { }

    productCodeWithoutCPI: string;

    setQuantity(code: string,quantity: number): void {
        this.quantityMap.set(code, quantity);
    }

    getQuantity(code: string): number {
        return this.quantityMap.has(code) ? this.quantityMap.get(code) : 1;
    }

    getCPIPrice(userEmail: string, product: ProductExtended, b2bChanged?: boolean): Observable<any> {
        if (this.cpiPriceRes$$.value?.code === product.code && !b2bChanged) {
            return of(this.cpiPriceRes$$.value.price);
        }
        const companyNumber = localStorage.getItem('companyNumber');
        const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/company/${companyNumber}/cpiPrice`;
        const params = {
            code: product.code,
            quantity: 1
        }
        this.productCodeWithoutCPI = null;
        this.cpiPrice$ = combineLatest([
            this.httpClient.get(url, { params }),
            this.currentProductService.getProduct()])
            .pipe(
                tap(([res, currentProduct]) => {
                    if (res && (!currentProduct || (currentProduct && currentProduct.code == product.code))) this.cpiPriceRes$$.next(res);
                }),
                switchMap(() => this.httpClient.get(url, { params })),
                catchError((error) => {
                    this.cpiPriceRes$$.next(null);

                    if (!product.price?.value || product.erpStatus === ErpStatus.Discontinued) {
                        return throwError('No NET Price');
                    }
                    return this.baseStoreService.getPhoneNumber()
                        .pipe(
                            tap(phoneNumber => {
                                if (phoneNumber) {
                                    this.productCodeWithoutCPI = product?.code;
                                    if (error?.error?.message) {
                                        this.globalMessageService.add(
                                            error.error.message,
                                            GlobalMessageType.MSG_TYPE_ERROR
                                        );
                                        throw ('No NET Price');
                                    } else {
                                        this.globalMessageService.add(
                                            {
                                                key: 'httpHandlers.netPriceError',
                                                params: { phoneNumber: phoneNumber.contactInfo }
                                            },
                                            GlobalMessageType.MSG_TYPE_ERROR
                                        );

                                        throw ('No NET Price');
                                    }
                                }
                            })
                        )
                })
            );
        return this.cpiPrice$;
    }

    updateRecentlyViewed(productCode: string): Observable<any> {
        return this.httpClient.patch(
            `${this.occEndpoints.getBaseUrl()}/users/current/viewedProduct/${productCode}`,
            {}
        )
    }

    getProductTabReferences(productCode: string, referenceType: string = 'LIQUID_COOLED_ACCESSORIES'): Observable<any> {
        return this.httpClient.get(
            `${this.occEndpoints.getBaseUrl()}/products/${productCode}/tab-references?fields=DEFAULT&referenceTypes=${referenceType}`,
            {}
        )
    }

    getApplicableProducts(productCode: string, currentPage: number): Observable<ApplicableProductsResponse> {
        return this.httpClient.get(
            `${this.occEndpoints.getBaseUrl()}/products/${productCode}/applicable-products?currentPage=${currentPage}`,
            {}
        ).pipe(
            map((response: any): ApplicableProductsResponse => {
                const mappedPagination = {
                    currentPage: response.pagination.page,
                    totalPages: response.pagination.totalPages,
                    totalResults: response.pagination.totalCount,
                    pageSize: response.pagination.count,
                };
                const mappedProducts: ProductExtended[] = response.results.map((product: Product) => {
                    return this.converter.convert({
                        ...product,
                        productCode: product.code,
                    }, PRODUCT_NORMALIZER);
                });
                return {
                    results: mappedProducts,
                    pagination: mappedPagination,
                };
            })
        )
    }

    searchSuggestionsByModelNumber(query: string): Observable<ProductSearchPage> {
        return this.httpClient.get<ProductSearchPage>(`${this.occEndpoints.getBaseUrl()}/products/suggestionModels/${query}?fields=code,name`);
    }

    searchSpByModelOrSerialNumber(modelNumber: string, queryParams: SearchConfig, query?: string,  auxiliary?: boolean): Observable<ProductSearchPage> {
        return this.httpClient.get<ProductSearchPage>(`${this.occEndpoints.getBaseUrl()}/serviceparts/search?fields=products(FULL),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery,keywordRedirectUrl&query=${modelNumber}${query ? (`:${query?.indexOf(':') > -1 ? (!(query?.indexOf(':servicePart:true') > -1) ? query.slice(query?.indexOf(':'),query?.length) : query) : `${query}:relevance:servicePart:true` }`) : ''}&${new HttpParams({fromObject: queryParams as any}).toString()}`)
            .pipe(
                take(1),
                this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER),
                tap((data: ProductSearchPage) => {
                    this.store.dispatch(
                        new ProductActions.SearchProductsSuccess(
                            data,
                            auxiliary
                        )
                    )
                }),
                catchError((error) => {
                    this.store.dispatch(
                        new ProductActions.SearchProductsFail(
                            normalizeHttpError(error, this.logger),
                            auxiliary
                        )
                    )
                    return of();
                })
            );
    }

}
